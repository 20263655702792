import { environment } from "../../../environments/environment";

export const FOOTER_PAGES = {
    CONTACT: 'contact',
    DOWNLOAD: 'download',
    SHARE: 'share',
    ABOUT: 'about',
} as const;

export const PAGE_LINK = 'http://localhost:4200/';

export const API = {
    USER_DATA: `${environment.apiUrl}/getUserData?secretKey=`,
    ABOUT_US: `${environment.apiUrl}/aboutPageDetails`,
    REGISTER_INQUIRY: `${environment.apiUrl}/registerInquiry`,
}