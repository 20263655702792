import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { API } from '../core/constants/app.constant';
import { APIResponse, AboutUsPage, Inquiry, UserInfo } from '../core/models/comon.model';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  userInfo: BehaviorSubject<APIResponse<UserInfo> | null> =
    new BehaviorSubject<APIResponse<UserInfo> | null>(null);

  constructor(
    private http: HttpClient,
  ) { }

  ProjectSetting(param: string): Observable<APIResponse<UserInfo>> {
    const headers = new HttpHeaders().set('token', param);
    return this.http.get<APIResponse<UserInfo>>(`${API.USER_DATA}${param}`, { headers });
  }

  loadAppInfo(param: string) {
    return this.ProjectSetting(param).subscribe((res) => this.userInfo.next(res));
  }

  getAboutUsPageInfo(): Observable<APIResponse<AboutUsPage>> {
    return this.http.get<APIResponse<AboutUsPage>>(API.ABOUT_US);
  }

  registerInquiry(param: Inquiry): Observable<APIResponse<[]>> {
    return this.http.post<APIResponse<[]>>(API.REGISTER_INQUIRY, param);
  }
}
